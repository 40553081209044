<template>
  <div>
    <div v-if="loading" class="col-12 text-center">
      <div
        class="m-loader m-loader--danger"
        style="width: 30px; display: inline-block"
      ></div>
    </div>

    <div v-else class="bd-example bd-example-images">
      <div class="row">
        <div
          class="col-lg-4 mb-3"
          v-for="(file, index) in fileList"
          :key="index"
        >
          <img
            class="img-thumbnail cursor-pointer"
            :src="file.url"
            data-holder-rendered="true"
            :alt="file.name"
            :style="`width: ${width}px; height: ${height}px; display: block`"
          />

          <button @click="excluded(index)" class="btn btn-danger btn-sm mt-2">
            Remover
          </button>
        </div>
      </div>

      <hr v-if="fileList.length" />

      <div class="row">
        <div class="col-lg-4 mb-3" v-for="(file, index) in files" :key="index">
          <img
            class="img-thumbnail cursor-pointer"
            :src="file.url"
            data-holder-rendered="true"
            :alt="file.name"
            :style="`width: ${width}px; height: ${height}px; display: block`"
          />

          <button
            v-if="$authorization.can('service-update')"
            @click="remove(file.id, index)"
            class="btn btn-danger btn-sm mt-2"
          >
            Remover
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteFile } from "../../services/FileService";

export default {
  name: "Gallery",

  props: {
    files: {
      type: Array,
      default() {
        return [];
      }
    },

    fileList: {
      default() {
        return [];
      }
    },

    width: {
      type: String,
      default: "300"
    },

    height: {
      type: String,
      default: "250"
    }
  },

  data() {
    return {
      loading: false
    };
  },

  methods: {
    excluded(index) {
      this.fileList.splice(index, 1);
    },
    remove(fileID, index) {
      swal({
        title: "Tem certeza que deseja Deletar?",
        text: "Uma vez deletado não poderá mais recuperar esse registro.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo deletar"
      }).then(willDelete => {
        if (willDelete.value) {
          this.loading = true;

          deleteFile(fileID)
            .then(res => {
              this.files.splice(index, 1);
              swal("Sucesso!", "Deletado com sucesso.", "success");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>