export const deleteFile = fileID => {
  return axios.delete(`api/files/${fileID}`);
};

export const downloadFile = (path, fileName) => {
  return axios.get(`service/file/download?path=${path}&filename=${fileName}`, {ResponseType: 'arraybuffer'});
}

export default {
  deleteFile,
  downloadFile
};
